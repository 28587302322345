export const fullScreenVideoTextMock = [
  {
    id: 1,
    x: '-10%',
    text: 'Уникальные атмосфера',
    className: 'text-left',
  },
  {
    id: 2,
    x: '10%',
    text: 'и комфорт, созданные путем',
    className: 'text-right',
  },
  // {
  //   id: 3,
  //   x: '-20%',
  //   text: 'созданные путем',
  //   className: 'text-left',
  // },
  {
    id: 3,
    x: '20%',
    text: 'индивидуальных дизайнерских',
    className: 'text-left',
  },
  {
    id: 4,
    x: '-20%',
    text: 'и технологических решений',
    className: 'text-right',
  },
]

// Уникальные атмосфера и комфорт, созданные путем индивидуальных дизайнерских и технологических решений.
