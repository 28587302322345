type TextDataProps = {
  id: number
  title: string
  description: string
}

export const textData: TextDataProps[] = [
  {
    id: 1,
    title: '14 лет',
    description:
      'опыт работы в отрасли (за долгий опыт работы компания приобрела все необходимые компетенции для выполнения заказа «под ключ»).',
  },
  {
    id: 2,
    title: 'с 2016 года ',
    description:
      'работаем в сегменте PREMIUM (индивидуальный подход, новые технологии и опыт позволяют реализовывать проекты на высшем уровне).',
  },
  {
    id: 3,
    title: '5 лет',
    description:
      'работаем с Министерством обороны Российской Федерации, а также Министерством здравоохранения Российской Федерации (работа на объектах МО и Минздрава всегда связана со сложными задачами, сжатыми сроками – за годы собрана команда профессионалов, позволяющая решать данные задачи).',
  },
  {
    id: 4,
    title: '1 место',
    description:
      'в номинации Russian Elevator Weeks 2017 (лучший лифт Российского производства)',
  },
]
